import {
  addGlobalContexts,
  clearGlobalContexts,
  enableActivityTrackingCallback,
  newTracker,
  trackPageView as snowplowTrackPageView,
  trackStructEvent,
} from '@snowplow/browser-tracker';

import {
  LinkClickTrackingPlugin,
  trackLinkClick,
} from '@snowplow/browser-plugin-link-click-tracking';
import EventViewTracker from 'src/lib/tracker/EventViewTracker';
import { standardSourceName } from '../../utils/sourceName';

const eventViewTracker = new EventViewTracker();
export default class EvvntTracker {
  constructor(publisher, frameConfig = {}) {
    this.publisher = publisher;
    this.frameConfig = frameConfig;
    this.tracker = this.initializeTracker();
    this.setupGlobalContexts();
    this.setupListeners();
  }

  initializeTracker() {
    return newTracker('plugin-tracker', process.env.SNOWPLOW_COLLECTOR_URL, {
      appId: 'discovery-plugin',
      discoverRootDomain: true,
      eventMethod: 'beacon',
      stateStorageStrategy: 'cookieAndLocalStorage',
      cookieSameSite: 'Lax',
      cookieSecure: true,
      plugins: [LinkClickTrackingPlugin()],
      contexts: {
        webPage: true,
      },
    });
  }

  setupGlobalContexts() {
    const envContext = {
      schema: 'iglu:com.evvnt/environment/jsonschema/1-0-0',
      data: {
        env: process.env.PLUGIN_ENV || 'development',
        service: 'Discovery Plugin',
        version: process.env.PLUGIN_VERSION,
      },
    };

    const pluginContext = {
      schema: 'iglu:com.evvnt/discovery_plugin/jsonschema/1-1-0',
      data: {
        provider: 'marketing',
        type: this.frameConfig.type,
        publisherId: this.publisher.id,
        url: this.publisher.url,
        orientation: this.frameConfig.options.landscape ? 'horizontal' : 'vertical',
      },
    };

    // Not sure why this is necessary, but if we don't do this, the contexts are duplicated.
    clearGlobalContexts();
    addGlobalContexts([envContext, pluginContext]);
  }

  setupListeners() {
    enableActivityTrackingCallback({
      minimumVisitLength: 1,
      heartbeatDelay: 3,
      callback: () => {
        this.pushPendingEventViews();
      },
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.pushPendingEventViews();
      }
    });
  }

  pushPendingEventViews() {
    const contexts = eventViewTracker.getPendingViews();
    if (contexts.length > 0) {
      trackStructEvent({
        category: 'Event Views',
        action: 'group_event_view',
        context: contexts,
      });
    }
  }

  trackPageView() {
    snowplowTrackPageView();
  }

  /**
   *
   * @param event {Object}
   * @param viewedFrom {String} The name of the view source. f.e. event_page, event_list, featured
   * @return void
   */

  trackEventView(event, viewedFrom) {
    eventViewTracker.addViewToQueue(event, viewedFrom);
  }

  trackEventClick(event, elementProps) {
    trackLinkClick({
      targetUrl: elementProps.targetUrl,
      elementId: elementProps.elementId,
      elementClasses: elementProps.elementClasses,
      elementTarget: elementProps.elementTarget,
      elementContent: elementProps.elementContent,
      context: [this.createEventContext(event)],
    });
  }

  createEventContext(event) {
    return {
      schema: 'iglu:com.evvnt/event/jsonschema/1-0-0',
      data: {
        source: standardSourceName(event.mainSource),
        sourceOccurrenceId: String(event.sourceId),

        // TODO: We are not capturing the event parent id for most sources, only for Evvnt.
        sourceEventId: String(event.parentId),
      },
    };
  }
}
