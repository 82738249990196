import Queue from './Queue';
import { standardSourceName } from 'src/utils/sourceName';

export default class EventViewTracker {
  constructor() {
    this.queue = new Queue();
  }

  getPendingViews() {
    return this.queue.flush();
  }

  addViewToQueue(event, viewedFrom) {
    this.queue.add(this.createEventViewContext(event, viewedFrom));
  }

  createEventViewContext(event, viewedFrom) {
    return {
      schema: 'iglu:com.evvnt/event_view/jsonschema/2-0-0',
      data: {
        viewedFrom: viewedFrom,
        source: standardSourceName(event.mainSource),
        sourceOccurrenceId: String(event.sourceId),

        // TODO: We are not capturing the event parent id for most sources, only for Evvnt.
        sourceEventId: String(event.parentId),
      },
    };
  }
}
