import React, { Suspense } from 'react';
import { QueryRouter } from 'src/lib/router';
import { Routes, Route } from 'src/lib/router';
import { usePluginConfig } from './contexts/pluginContext';
import PluginFrame from './utils/PluginFrame';
import { useTracking } from './contexts/trackingContext';
import { useInView } from 'react-intersection-observer';

const EventPage = React.lazy(() => import('src/pages/event'));
const HomePage = React.lazy(() => import('src/pages/index'));
const WidgetPage = React.lazy(() => import('src/pages/widget'));
const EventPreviewPage = React.lazy(() => import('src/pages/event_preview'));
const EventWidgetPage = React.lazy(() => import('src/pages/event_widget'));
const NotFoundPage = React.lazy(() => import('src/pages/404'));

const App = () => {
  const pluginConfig = usePluginConfig();
  const frameConfig = pluginConfig.frameConfig;
  const pluginFrame = new PluginFrame(frameConfig);
  const onRouteChangeStart = () => {
    pluginFrame.rootNode.scrollIntoView({ behavior: 'instant', block: 'start' });
  };

  const { tracker } = useTracking();
  const { ref } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        tracker.trackPageView();
      }
    },
  });
  const pluginTypeSelector = () => {
    if (frameConfig.type === 'calendar') {
      return (
        <QueryRouter pathParam="_evDiscoveryPath" onRouteChangeStart={onRouteChangeStart}>
          <Suspense fallback="Loading...">
            <Routes>
              <Route path="/" component={<HomePage />} />
              <Route path="/:category" component={<HomePage />} />
              <Route path="/event/:eventId" component={<EventPage />} />
              <Route path="/:splat*" component={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </QueryRouter>
      );
    }

    if (frameConfig.type === 'widget') {
      return <WidgetPage />;
    }

    if (frameConfig.type === 'preview_event') {
      return <EventPreviewPage />;
    }

    if (frameConfig.type === 'single_event_widget') {
      return <EventWidgetPage />;
    }
  };

  return (
    <div id="frame-container" className="@container" style={{ width: '100%' }} ref={ref}>
      {pluginTypeSelector()}
    </div>
  );
};

export default App;
