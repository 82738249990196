/*
    This function will take legacy
 */
export function prepareAppProperties(options) {
  let type = 'calendar';
  let data = {};
  if (options.discovery && options.discovery.widget) {
    type = 'widget';
  } else if (options.discovery && options.discovery.single_event_widget) {
    type = 'single_event_widget';
    data = {
      event_id: options.discovery.event_id,
    };
  } else if (options.preview_event) {
    type = 'preview_event';
    data = {
      event_json: options.preview_event,
    };
  } else if (options.discovery && options.discovery.button) {
    throw new Error('Button is not supported in the new plugin');
  }


  const hasCategoryId = options.discovery && options.discovery.category_id;
  const categoryId = hasCategoryId ? parseInt(options.discovery.category_id) : null;

  let frameConfig = {
    publisher_id: options.publisher_id,
    // Could be calendar, widget, single_event_widget or event_preview.
    type: type,
    container: options.discovery.element,
    data: data,
    options: {
      p_id: options.publisher_id, // Backwards compatibility.
      widget: type === 'widget', // Backwards compatibility.
      landscape: options.discovery && options.discovery.orientation === 'landscape',
      number: options.discovery && options.discovery.number || 4,
      map: options.discovery && options.discovery.map,
      virtual: options.discovery && options.discovery.virtual,
      category_id: isNaN(categoryId) ? null : categoryId,
      d_backfill_images: !!(
        options.discovery && options.discovery.backfill_events_have_images
      ),
      display_ads: !!(options.discovery && options.discovery.display_ads),
    },
  };

  if (options.discovery && options.discovery.detail_page_enabled === false) {
    frameConfig.options.disable_details_page = true;
  }

  if (
    options.discovery &&
    options.discovery.navigation_categories &&
    options.discovery.navigation_categories.length > 0
  ) {
    frameConfig.options.navigation_categories = options.discovery.navigation_categories;
  }

  return frameConfig;
}
