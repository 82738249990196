import { createContext, useContext, useEffect } from 'react';
import EvvntTracker from 'src/lib/tracker/EvvntTracker';
import { useSelector } from 'src/hooks/useSelector';
import TagManager from 'react-gtm-module';

const TrackingContext = createContext();

function TrackingProvider(props) {
  const publisherSettings = useSelector((state) => state.publisherSettings);
  const frameConfig = useSelector((state) => state.frameConfig);
  const tracker = new EvvntTracker(publisherSettings.publisher, frameConfig);

  useEffect(() => {
    let gtmId;
    let gtmEnv;
    let gtmAuth;

    if (frameConfig.type === 'widget') {
      gtmId = process.env.GTM_WIDGET_ID;
      gtmEnv = process.env.GTM_WIDGET_ENV;
      gtmAuth = process.env.GTM_WIDGET_AUTH;
    } else {
      gtmId = process.env.GTM_CALENDAR_ID;
      gtmEnv = process.env.GTM_CALENDAR_ENV;
      gtmAuth = process.env.GTM_CALENDAR_AUTH;
    }

    if (gtmId && gtmAuth) {
      TagManager.initialize({
        gtmId: gtmId,
        auth: gtmAuth,
        preview: gtmEnv,
      });
    }
  }, []);

  return (
    <TrackingContext.Provider value={{ tracker }}>{props.children}</TrackingContext.Provider>
  );
}

const useTracking = () => useContext(TrackingContext);

export { TrackingProvider, useTracking };
