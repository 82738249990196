class Queue {
  constructor() {
    this.items = [];
  }

  flush() {
    return this.items.splice(0, this.size());
  }

  add(item) {
    this.items.push(item);
  }

  size() {
    return this.items.length;
  }

  isEmpty() {
    return this.items.length === 0;
  }
}

export default Queue;
