export function redirectFromLegacyEvId() {
  const urLSearchParams = new URLSearchParams(window.location.search);
  const eventId = urLSearchParams.get('_ev_id') || urLSearchParams.get('_ev_Id');

  if (!eventId) {
    return;
  }

  const currentUrl = window.location.href;
  // Split the URL into the base URL and the query string
  const [baseUrl] = currentUrl.split('?');

  // Create the new parameter
  const searchParams = new URLSearchParams();
  searchParams.set('_evDiscoveryPath', `/event/${eventId.replace(/_/g, '-')}`);

  window.location.href = `${baseUrl}?${decodeURIComponent(searchParams.toString())}`;
}
