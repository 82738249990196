import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useAsyncError from '../hooks/useAsyncError';
import PluginApi from '../apis/pluginApi';
import LegacyDiscoveryApi from "../apis/legacyDiscovery"

const PluginContext = createContext({
  baseUrl: '/',
  isFramed: true,
  frameConfig: {},
  hostPageData: {},
  publisherSettings: {},
  rootCategories: [],
  rawFacets: [],
});

function PluginProvider(props) {
  const [isStateReady, setStateReady] = useState(false);
  const throwError = useAsyncError();
  const [frameConfig] = useState(props.frameConfig);
  const [hostPageData] = useState(props.hostPageData);
  const [publisherSettings, setPublisherSettings] = useState({});
  const [rootCategories, setRootCategories] = useState([]);
  const [rawFacets, setRawFacets] = useState([]);

  const pluginConfig = useMemo(() => {
    return {
      baseUrl: '/',
      isFramed: true,
      frameConfig,
      hostPageData,
      publisherSettings,
      rootCategories,
      rawFacets,
      setRawFacets,
      setRootCategories,
    };
  }, [frameConfig, hostPageData, publisherSettings, rootCategories, rawFacets]);

  useEffect(() => {
    const prepareData = async () => {
      const client = new PluginApi();
      const legacyClient = new LegacyDiscoveryApi();
      const publisherSettings = await client.getPublisherSettings(frameConfig.publisher_id);
      setPublisherSettings(publisherSettings);

      let rootCategories;

      // TODO: Improve this logic, let's make a single request to the API to get
      // both the publisher settings and the root categories
      if (frameConfig.options.category_id) {
        rootCategories = await legacyClient.getCategoriesById(frameConfig.options.category_id);
      } else if (frameConfig.options.navigation_categories) {
        rootCategories = await legacyClient.getCategoriesById(
          frameConfig.options.navigation_categories
        );
      } else {
        rootCategories = publisherSettings.publisher.root_categories;
      }

      setRootCategories(rootCategories);
    };

    prepareData()
      .then(() => {
        setStateReady(true);
      })
      .catch((e) => {
        throwError(new Error('Error while fetching data from the API: ' + e.message || ''));
      });
  }, []);

  if (!isStateReady) {
    return (
      <div id="frame-container" className="@container">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <PluginContext.Provider value={pluginConfig}>{props.children}</PluginContext.Provider>
  );
}

const usePluginConfig = () => useContext(PluginContext);

export { PluginProvider, usePluginConfig };
