import RouteContext from 'src/lib/router/contexts/routeContext';
import { useMemo, cloneElement, Fragment, useEffect, useRef } from 'react';
import { useQueryRouter } from 'src/lib/router/index';
import { extractParams, matchRoutes } from 'src/lib/router/utils';
import { ModalProvider } from 'react-modal-hook';
import PluginFrame from '../../utils/PluginFrame';
import { usePluginConfig } from '../../contexts/pluginContext';

/*
 * This component is responsible for rendering the correct page based on the
 * current location. It also provides the current params to the page.
 * It is used by the QueryRouter component.
 */
export default function Routes({ children }) {
  const { location, pathParam, rawLocation } = useQueryRouter();
  const frameConfig = usePluginConfig().frameConfig;
  const pluginFrame = new PluginFrame(frameConfig);
  // The pathParam is _evDiscoveryPath and determines the current location
  // however, additional parameters can be included in the query string
  // so the additionalParams are extracted and added to the params
  const additionalParams = extractParams(window.location.search, pathParam);
  console.debug('Additional params are: ', additionalParams);
  const match = useMemo(() => matchRoutes(children, location), [children, location]);

  const value = useMemo(() => {
    return { params: { ...match?.params, ...additionalParams } };
  }, [match, location, rawLocation]);

  console.log('Params after match are', value);
  // TODO: Render a 404 if path is invalid
  if (!match) return null;

  console.log('The key is: ', location);
  return (
    <RouteContext.Provider value={value}>
      <ModalProvider container={pluginFrame.rootNode}>
        <Fragment key={rawLocation}>{match?.component}</Fragment>
      </ModalProvider>
    </RouteContext.Provider>
  );
}
