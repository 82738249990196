const ErrorComponent = ({ error }) => {
  return (
    <div className="bg-red-100 border border-red-400 text-red-700 p-5 my-4 rounded w-1/2 mx-auto">
      <div className="flex items-center">
        <span className="inline-block h-6 w-6 bg-red-400 text-white rounded-full flex justify-center items-center">
          !
        </span>
        <p className="ml-2 text-sm font-semibold">{error.message}</p>
      </div>
    </div>
  );
};

export default ErrorComponent;
