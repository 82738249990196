/*
    This class handles the connection with the Discovery API
    which is in current running in NextJS
 */

import { toSortedQueryString } from '../../helpers/queryHelper';

export default class LegacyDiscoveryApi {
  constructor() {
    this.api_url = process.env.LEGACY_DISCOVERY_API_HOST
  }

  async getEventById(eventId, options) {
    const response = await fetch(
      `${this.api_url}/events/${eventId}?` + toSortedQueryString(options)
    );

    if (response.ok) {
      return await response.json();
    } else if (response.status === 404) {
      return { rawEvent: null };
    } else {
      throw new Error('Request failed with status ' + response.status);
    }
  }

  /*
    This method is used to get the events for the home page
    It will return the featured event, the editorial pick events, the rawFacets and the rawEvents
    All events are returned in raw format
  */
  async getHomePageEvents(publisherId, options = {}) {
    const response = await fetch(
      `${this.api_url}/publisher/${publisherId}/home_page_events?` +
        toSortedQueryString(options)
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }

  async getWidgetEvents(publisherId, filters) {
    const response = await fetch(
      `${this.api_url}/publisher/${publisherId}/widget_events?` +
      toSortedQueryString(filters)
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }

  async getRecommendedEvents(filters) {
    const response = await fetch(
      `${this.api_url}/events/recommendations?` + toSortedQueryString(filters)
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }

  async getEvents(filters) {
    const response = await fetch(`${this.api_url}/events?` + toSortedQueryString(filters));

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return response.json();
  }

  async getFeaturedEvents(filters) {
    return this.getEvents({ ...filters, isFeatured: true });
  }

  async getEditorialPickEvents(filters) {
    return this.getEvents({ ...filters, isEditorialPick: true });
  }
  async getPublisherSettings(publisher_id) {
    const response = await fetch(
      `${this.api_url}/publisher/${publisher_id}/publisher_settings`
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }

  async getCategoriesById(category_id) {
    const response = await fetch(
      `${this.api_url}/categories?` +
        toSortedQueryString({
          category_id: category_id,
        })
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }

  async getCategoriesByParentId(navigation_categories_ids) {
    const response = await fetch(
      `${this.api_url}/categories?` +
        toSortedQueryString({
          navigation_categories_ids: navigation_categories_ids,
        })
    );

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status);
    }

    return await response.json();
  }
}
