export function getHostPageData() {
  return {
    pageTitle: getCurrentTitle(),
    pageDescription: getCurrentPageDescription(),
    baseUrl: getHostUrl(),
  };
}

function getCurrentTitle() {
  return document.title;
}

function getHostUrl() {
  const currentURL = window.location.href;
  return currentURL.split('?')[0];
}

function getCurrentPageDescription() {
  var descriptionTag = document.head.querySelector("meta[name='description']");
  return descriptionTag ? descriptionTag.getAttribute('content') : '';
}
