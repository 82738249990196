import TailwindCss from 'src/assets/styles/tailwind.css';
import GlobalCss from 'src/assets/styles/global.css';
import CarouselCss from 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactDayPickerCss from 'react-day-picker/lib/style.css';
import swiperBundle from 'swiper/css/bundle';
import SkeletonCss from 'react-loading-skeleton/dist/skeleton.css';

export function setupShadowDom(container) {
  const containerElement = document.querySelector(container);
  if (!containerElement) {
    throw new Error(`Container element ${container} not found`);
  }
  const shadowRoot = containerElement.attachShadow({ mode: 'open' });

  addStyleSheet(shadowRoot, TailwindCss.toString());
  addStyleSheet(shadowRoot, ReactDayPickerCss.toString());
  addStyleSheet(shadowRoot, GlobalCss.toString());
  addStyleSheet(shadowRoot, SkeletonCss.toString());

  // TODO: We don't need to load the following CSS in every page but only in the pages that use the components.
  addStyleSheet(shadowRoot, CarouselCss.toString());
  addStyleSheet(shadowRoot, swiperBundle.toString());

  return shadowRoot;
}

function addStyleSheet(shadowRoot, css) {
  const style = document.createElement('style');
  style.textContent = css;
  shadowRoot.appendChild(style);
}
