/*
  Should match source name listed on iglu:com.evvnt/event/jsonschema/1-0-0
 */
export function standardSourceName(sourceName) {
  if (sourceName === 'evvnt') {
    return 'marketing';
  } else if (sourceName === 'geotix') {
    return 'core';
  } else {
    return sourceName;
  }
}
