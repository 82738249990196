// This wrapper helps transition from the NextJS router to React Router
import { useQueryRouter } from 'src/lib/router';
import { useRouteParams } from 'src/lib/router';

export function useRouter() {
  const { location, push, pathParam, rawLocation } = useQueryRouter();
  const { params } = useRouteParams();

  return {
    // The current path without the base path. Base path is not supported yet, leaving it for
    // backwards compatibility with the NextJS router.
    pathname: location,

    // The current path observed within the the pathParam.
    asPath: location,

    // The query parameters presented as an object, it excludes the pathParam.
    query: params,

    // Function to push a new route
    push: push,

    // The name of the query parameter, f.e. evDiscoveryPath
    pathParam: pathParam,

    // The entire URL as displayed in the user's browser.
    rawLocation: rawLocation,
  };
}
