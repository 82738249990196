import React from 'react';
import { match as matcher } from 'path-to-regexp';
export function extractPathFromSearchParams(currentPath, pathParam) {
  const searchParams = new URLSearchParams(currentPath);
  return searchParams.get(pathParam) || '/';
}

export function matchRoutes(children, location) {
  const matches = [];

  React.Children.forEach(children, (route) => {
    const fn = matcher(route.props.path, { decode: decodeURIComponent });
    const match = fn(location);

    if (match) {
      const params = match.params;

      matches.push({
        component: route.props.component,
        params: params,
      });
    }
  });
  return matches[0];
}

export function extractParams(queryParams, pathParam) {
  let params = new URLSearchParams(queryParams);
  params.delete(pathParam);

  let paramObj = {};
  for (const value of params.keys()) {
    paramObj[value] = params.get(value);
  }

  return paramObj;
}
