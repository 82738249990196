import { createContext } from 'react';

const RouterContext = createContext({
  location: '', // This is the location based on the pathParam
  rawLocation: '', // This is the full location including the pathParam
  pathParam: '',
  push: () => {},
});

export default RouterContext;
