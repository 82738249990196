import RouteContext from './contexts/routeContext';
import RouterContext from './contexts/routerContext';
import QueryRouter from './QueryRouter';
import { RouterLink } from './RouterLink';
import { useContext } from 'react';
import Route from './Route';
import Routes from './Routes';
const useQueryRouter = () => useContext(RouterContext);
const useRouteParams = () => useContext(RouteContext);

export { QueryRouter, RouterLink, useQueryRouter, useRouteParams, Route, Routes };
