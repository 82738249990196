import { replaceOrCreateTagWithValue } from './utils/tags';

export class ParentFrame {
  constructor(pluginFrame, config) {
    this.pluginFrame = pluginFrame;
    this.config = config;
    this.setup_scroll_state();
    this.setup_full_screen_state();
  }

  setupListeners() {
    window.addEventListener('message', (e) => {
      switch (e.data.type) {
        case 'evvnt_full_screen_state':
          this.update_full_screen_state(e.data.value);
          break;
        case 'evvnt_open_snap_sell':
          this.open_snap_sell(
            e.data.value.api_key,
            e.data.value.publisher_id,
            e.data.value.primary_button_color,
            e.data.value.primary_button_text_color
          );
          break;
        case 'evvnt_close_form':
          this.close_snap_sell();
          break;
        case 'evvnt_update_metadata':
          this.update_metadata(e.data);
          break;
      }
    });
  }

  setup_scroll_state() {
    // TODO - not on widget.
    window.history.scrollRestoration = 'manual';
    this.shouldScrollRestore = false;
    this.cachedScrollPositions = [];
  }

  setup_full_screen_state() {
    this.is_full_screen = false;
  }

  update_scroll_state(event) {
    // TODO: This is legacy code that needs to be reviewed.

    if (!this.cachedScrollPositions) {
      return;
    }

    if (event === 'beforePopState') {
      if (this.cachedScrollPositions.length) {
        var scrollPosition = this.cachedScrollPositions.pop();
        this.shouldScrollRestore = { x: scrollPosition[0], y: scrollPosition[1] };
      }
    }

    if (event === 'routeChangeStart') {
      this.cachedScrollPositions.push([window.scrollX, window.scrollY]);
    }

    if (event === 'routeChangeComplete') {
      if (this.shouldScrollRestore) {
        var shouldScrollRestore = this.shouldScrollRestore;
        window.scrollTo(shouldScrollRestore.x, shouldScrollRestore.y);

        // Sometimes it doesn't catch first time, so try again.
        setTimeout(function () {
          if (window.scrollY !== shouldScrollRestore.y) {
            window.scrollTo(shouldScrollRestore.x, shouldScrollRestore.y);
          }
        }, 50);

        this.shouldScrollRestore = false;
      }
    }
  }

  update_full_screen_state(isFullScreen) {
    if (isFullScreen === this.is_full_screen) {
      return;
    }

    if (isFullScreen) {
      this.update_scroll_state('routeChangeStart');
      this.pluginFrame.rootNode.classList.add('evvnt-full-screen');
    } else {
      this.update_scroll_state('beforePopState');
      this.update_scroll_state('routeChangeComplete');
      this.pluginFrame.rootNode.classList.remove('evvnt-full-screen');
    }

    this.is_full_screen = isFullScreen;
  }

  open_snap_sell(api_key, publisher_id, button_color, button_text_color) {
    var extra_params = 'with_navbar&partner=' + api_key;
    extra_params += '&publisher_id=' + publisher_id;
    extra_params += '&button_color=' + encodeURIComponent(button_color);
    extra_params += '&button_text_color=' + encodeURIComponent(button_text_color);

    var url = this.config.DISCOVERY_PLUGIN_HOST + '/snap_sell_form.html?' + extra_params;
    this.submission_form_iframe =
      '<div style="position: fixed; right: 0; top: 0; width: 100%; height: 100%; overflow: auto!important; -webkit-overflow-scrolling: touch!important; z-index: 9999999">' +
      '<iframe title="Event submission form" id="main_submission_iframe" src="' +
      url +
      '" style="width:100%; height:100%;" frameBorder="0"></iframe>' +
      '</div>';

    var container = document.createElement('DIV');
    container.id = 'evvnt-submission-form';
    container.innerHTML = this.submission_form_iframe;
    document.body.appendChild(container);
  }

  close_snap_sell() {
    document.getElementById('evvnt-submission-form').remove();
  }

  // This function will update the parent window tags with the given data.
  // Data expects the following keys:
  // - item: object
  //   - title: string (optional)
  //   - description: string (optional)
  //   - canonicalURL: string (optional)
  //   - absoluteHref: string (optional)
  //   - imageURL: string (optional)
  // - pageTitle: string (optional)
  update_metadata(event) {
    var data = event.value;

    if (data.item.canonicalURL) {
      replaceOrCreateTagWithValue('link', "link[rel='canonical']", {
        rel: 'canonical',
        href: data.item.canonicalURL,
      });
    }

    if (data.item.description) {
      replaceOrCreateTagWithValue('meta', "meta[name='description']", {
        name: 'description',
        content: data.item.description,
      });
    }

    if (data.item.absoluteHref) {
      replaceOrCreateTagWithValue('meta', "meta[property='og:url']", {
        property: 'og:url',
        content: data.item.absoluteHref,
      });
    }

    if (data.pageTitle) {
      document.title = data.pageTitle;

      replaceOrCreateTagWithValue('meta', "meta[property='og:title']", {
        property: 'og:title',
        content: data.pageTitle,
      });
    }

    if (data.item.description) {
      replaceOrCreateTagWithValue('meta', "meta[property='og:description']", {
        property: 'og:description',
        content: data.item.description,
      });
    }

    if (data.item.absoluteHref) {
      replaceOrCreateTagWithValue('meta', "meta[property='twitter:site']", {
        property: 'twitter:site',
        content: data.item.absoluteHref,
      });
    }

    if (data.item.imageURL) {
      replaceOrCreateTagWithValue('meta', "meta[property='twitter:card']", {
        property: 'twitter:card',
        content: data.item.imageURL ? 'summary_large_image' : 'summary',
      });
    }

    if (data.item.imageURL) {
      replaceOrCreateTagWithValue('meta', "meta[property='og:image']", {
        property: 'og:image',
        content: data.item.imageURL,
      });

      replaceOrCreateTagWithValue('meta', "meta[property='twitter:image']", {
        property: 'twitter:image',
        content: data.item.imageURL,
      });
    } else {
      // no image data for this page; remove stale elements:
      var elements = document.head.querySelectorAll(
        ["meta[property='twitter:image']", "meta[property='og:image']"].join(',')
      );
      for (var i = 0; i < elements.length; i++) {
        elements[i].remove();
      }
    }
  }
}
