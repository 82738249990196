import { extractPathFromSearchParams } from 'src/lib/router/utils';
import { reduceObject } from 'src/utils/objects';

export function fetchNewLocation(arg, pathParam) {
  if (typeof arg === 'string') {
    return handleLocationString(arg, pathParam);
  } else if (typeof arg === 'object') {
    return handleLocationObject(arg, pathParam);
  } else {
    throw new Error('Argument is of an unsupported type');
  }
}

export function convertToHref(to, pathParam) {
  if (typeof to === 'string') {
    return convertToHrefFromString(to, pathParam);
  } else if (typeof to === 'object') {
    return convertToHrefFromObject(to, pathParam);
  } else {
    throw new Error('Argument is of an unsupported type');
  }
}

function handleLocationString(arg, pathParam) {
  const rawLocation = convertToHrefFromString(arg, pathParam);
  return {
    newRawLocation: rawLocation,
    newLocation: extractPathFromSearchParams(rawLocation, pathParam),
    shallowMode: false,
  };
}

function handleLocationObject(arg, pathParam) {
  const { shallow = false } = arg;

  const rawLocation = convertToHrefFromObject(arg, pathParam);
  return {
    newRawLocation: rawLocation,
    newLocation: extractPathFromSearchParams(rawLocation, pathParam),
    shallowMode: shallow,
  };
}

function convertToHrefFromString(to, pathParam) {
  const effectivePathName = to.startsWith('/') ? to : `/${to}`;
  return `?${pathParam}=${effectivePathName}`;
}

function convertToHrefFromObject(to, pathParam) {
  const { pathname } = to;
  const query = reduceObject(to.query || {});
  const effectivePathName = pathname.startsWith('/') ? pathname : `/${pathname}`;
  let effectiveSearchParams = '';

  if (query && Object.keys(query).length > 0) {
    effectiveSearchParams = `&${new URLSearchParams(query).toString()}`;
  }

  return `?${pathParam}=${effectivePathName}${effectiveSearchParams}`;
}
