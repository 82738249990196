export function toSortedQueryString(params) {
  const queryObject = new URLSearchParams(params);

  // Sorting the query string is important for a stable cache key
  queryObject.sort();

  return queryObject.toString();
}

export function sanitizeQueryString(params) {
  Object.keys(params).forEach(key => {
    if (params[key] === undefined) {
      delete params[key];
    }
  });

  return toSortedQueryString(params);
}

export function findActiveLocation(query) {
  const countriesList = query.countries ? query.countries.split('^') : null;
  const townsList = query.towns ? query.towns.split('^') : null;

  if (townsList && townsList.length === 1) {
    return townsList[0];
  }

  if (countriesList && countriesList.length === 1 && !townsList) {
    return countriesList[0];
  }

  return null;
}
