export default class PluginFrame {
  constructor(frameConfig) {
    this.parentContainerSelector = frameConfig.container;
  }

  get shadowRoot() {
    return document.querySelector(this.parentContainerSelector).shadowRoot;
  }

  get rootNode() {
    return this.shadowRoot.querySelector('#frame-container');
  }

  getElementById(elementId) {
    return this.rootNode.querySelector(`#${elementId}`);
  }
}
