export const getNextPageUrl = (pageNumber) => {
  let query = new URLSearchParams(window.location.search);
  query.set('evPage', pageNumber);

  return `?${query.toString()}`;
};

export const iframeSendScrollState = (state) => {
  window.parent.postMessage(
    {
      type: 'evvnt_scroll_state',
      value: state,
    },
    '*'
  );
};

export const messagePathChange = (path) => {
  window.parent.postMessage(
    {
      type: 'evvnt_update_path',
      value: path,
    },
    '*'
  );
};

export const iframeFullScreenState = (state) => {
  window.parent.postMessage(
    {
      type: 'evvnt_full_screen_state',
      value: state,
    },
    '*'
  );
};

export const openSnapSell = (props) => {
  window.parent.postMessage(
    {
      type: 'evvnt_open_snap_sell',
      value: props,
    },
    '*'
  );
};

/**
 * Signals that the parent page's `<head>` metadata should be updated.
 */
export const updateParentMetadata = (props) => {
  window.parent.postMessage(
    {
      type: 'evvnt_update_metadata',
      value: props,
    },
    '*'
  );
};

export function isMobile() {
  return window.matchMedia('not all and (pointer: fine)').matches;
}
