import { createRoot } from 'react-dom/client';
import App from './app';
import ErrorComponent from 'src/components/molecules/ErrorComponent';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { prepareAppProperties } from 'src/host/utils/legacyProperties';
import { setupShadowDom } from './bridge';
import { ParentFrame } from './host/ParentFrame';
import { ErrorBoundary } from 'react-error-boundary';
import { redirectFromLegacyEvId } from './host/utils/redirect';
import { getHostPageData } from './host/utils/url';
import { PluginProvider } from './contexts/pluginContext';
import { TrackingProvider } from './contexts/trackingContext';
import PluginFrame from './utils/PluginFrame';

// eslint-disable-next-line no-unused-vars
function evvnt_require(_ignored) {
  return {
    init: function (options) {
      redirectFromLegacyEvId(); // This is a temporary redirect for legacy EV IDs.

      const domElementSelector = options.discovery.element;
      DiscoveryPlugin.init({
        container: domElementSelector,
        // Transformed from legacy to new options
        frameConfig: prepareAppProperties(options),
        hostPageData: getHostPageData(),
      });
    },
  };
}

const DiscoveryPlugin = {
  init: function (options) {
    const queryClient = new QueryClient();
    const shadowContainer = setupShadowDom(options.container);
    const root = createRoot(shadowContainer);

    const pluginFrame = new PluginFrame(options.frameConfig);
    const parentFrame = new ParentFrame(pluginFrame, {
      EVVNT_FORM_URL: process.env.EVVNT_FORM_URL,
      DISCOVERY_PLUGIN_HOST: process.env.DISCOVERY_PLUGIN_HOST,
    });
    parentFrame.setupListeners();

    console.debug('Rendering app, options: ', options);
    root.render(
      <ErrorBoundary fallbackRender={ErrorComponent}>
        <QueryClientProvider client={queryClient}>
          <PluginProvider
            frameConfig={options.frameConfig}
            hostPageData={options.hostPageData}
          >
            <TrackingProvider>
              <App />
            </TrackingProvider>
          </PluginProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    );
  },
};

window.evvnt_require = evvnt_require;
window.DiscoveryPlugin = DiscoveryPlugin;
