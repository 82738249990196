import { useRouter } from 'src/hooks/useRouter';
import { convertToHref } from 'src/lib/router/push';

export function RouterLink({ href, children, className }) {
  const { push, pathParam } = useRouter();

  const fullPath = convertToHref(href, pathParam);
  function handleClick(e) {
    e.preventDefault();
    push(href);
  }

  return (
    <a href={fullPath} className={className} onClick={handleClick}>
      {children}
    </a>
  );
}
