export function replaceOrCreateTagWithValue(tagName, tagSelector, attributes) {
  var tag = document.head.querySelector(tagSelector);

  if (tag) {
    setTagAttributes(tag, attributes);
  } else {
    tag = document.createElement(tagName);
    setTagAttributes(tag, attributes);
    document.head.appendChild(tag);
  }
}

function setTagAttributes(tag, attributes) {
  for (var key in attributes) {
    tag.setAttribute(key, attributes[key]);
  }
}
